import React, { Component } from "react";
import Graph from './Graph';
import {roleMap} from '../dropInText/businesses';
import '../css/Graphs.css';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {Grid, Row, Col} from 'react-bootstrap';
import { businessToRoleMap } from '../dropInText/businesses';
 
class AllGraphs extends Component {
  /* Return min, max values of all player data */
  getRange(playerData) {
    var all_data = []
    Object.keys(playerData).forEach((data) => {
      all_data = [...all_data, ...playerData[data]]
    })
    return {min: Math.min(...all_data)-1, max: Math.max(...all_data)+1}
  }

  render() {
    var playerMap = businessToRoleMap[this.props.business]
    var yDim = 350
    var margins = {l:40, r: 0, b: 75, t: 50, pad: 0}

    /* Get graphs for each set of players*/
    var inventory_graphs = []
    var order_graphs = []
    var inventoryRange = this.getRange(this.props.inventories)
    var orderRange = this.getRange(this.props.orders)

    for (var i=roleMap.length-1; i >= 0; i--) {
      inventory_graphs.push(
        <Col key={i} sm={3} md={3}>
            <Graph 
              showBullwhip={false}
              yTitle="Inventory"
              index={i}
              title={roleMap[i] === this.props.role ? playerMap[roleMap[i]] + " (You)" : playerMap[roleMap[i]]}
              data={this.props.inventories}
              role={this.props.role}
              business={this.props.business}
              height={yDim}
              player={roleMap[i]}
              margins={margins}
              yMin={inventoryRange.min}
              yMax={inventoryRange.max}
            />
      </Col>
      )
      order_graphs.push(
        <Col key={i} sm={3} md={3}>
            <Graph 
              yTitle="Orders"
              showBullwhip={false}
              index={i}
              title={roleMap[i] === this.props.role ? playerMap[roleMap[i]] + " (You)" : playerMap[roleMap[i]]}
              data={this.props.orders}
              role={this.props.role}
              business={this.props.business}
              height={yDim}
              player={roleMap[i]}
              margins={margins}
              yMin={orderRange.min}
              yMax={orderRange.max}
            />
        </Col>
      )
    }

    return (
      <div>
        <h2>Inventory</h2>
        <Grid fluid={true}>
          <Row>
            {inventory_graphs}
          </Row>
          <hr />
        <h2>Orders</h2>
          <Row>
            {order_graphs}
          </Row>
          <hr />
          <Row>
            <Col>
              <p className='kpi-list'>As you review your results and the results of the full supply chain, take a critical eye towards the following:</p>
              <ul className='kpi-list'>
                <li><b>Backlog</b> – # of stock outs that week (negative inventory) in any given week. These occur when the order placed was greater than the inventory you had available. Were there any nodes in the supply chain that had higher or lower periods of backlog? What do you think might have been the cause of these? Is there anything you could have done differently to improve your forecast?</li>
                <li><b>High Inventory</b> – # of products remaining in your “inventory” box. A high inventory means that your supply chain is not lean or minimizing its costs. Look for the peaks and low points on each of the graphs – did they occur at the same time or was there a slight delay?</li>
                  <li><b>Orders Placed</b> – When you look across the supply chain were there any periods where you appeared to be placing orders that were dramatically greater than the demand? What was driving this? Were you compensating because of low inventory? Did you not trust the order that was being placed? What would you have done differently to gain visibility into the demand?”</li>
                </ul>
            </Col>
          </Row>
        </Grid>
        <div className="btn-container col-container">
          <NavLink to="/closure">
            <Button bsStyle="default" bsSize="large">Next</Button>
          </NavLink>
        </div>
      </div>
    );
  }

}
 
export default AllGraphs;
