import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import { Button } from 'react-bootstrap';
 

class LandingPage extends Component {
  render() {
    var overlay;
    if (window.location.search !== '') {
      overlay = (
        <div className="overlay col-container">
            <a href="/" target="blank">
              <Button bsStyle="default" bsSize="large">Play In Full Screen</Button>
            </a>
        </div>
      )
    }

    return (
      <div>
        {overlay}
        <h2>Introduction</h2>
        <div className="col-container">
          <video width="640px" height="480px" controls controlsList="nodownload">
            <source src="/cxu/game/videos/introduction.mp4" type="video/mp4" />
          Your browser does not support this video type
          </video>
        </div>
        <div className='btn-container col-container'>
          <NavLink onClick={this.props.onStartClick} to="/mechanics">
            <Button bsStyle="default" bsSize="large">Start New Game</Button>
          </NavLink>
        </div>
        {this.props.gameFinished ? 
        <div className='btn-container col-container'>
          <NavLink to="/player_graphs">
            <Button bsStyle="default" bsSize="large">Last Game Graphs</Button>
          </NavLink>
        </div>
        : ""}
      </div>
    );
  }
}
 
export default LandingPage;
