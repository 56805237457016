import { 
  INCREMENT_STEP,
  PLACE_ORDER,
  RESET_GAME
} from '../actions/GameActions';
import { initialSettings, playerOrder, customerOrder } from './SettingsReducer';
import * as gameEngine from './GameEngine';


/* Initial Player State */
const initialPlayerState = {
  inventoryLog: [],
  orderLog: [],
  lastOrder: customerOrder,
  incomingOrder: 0,
  outbound: 0,
  inbound: 0,
  delayPipeline: Array.apply(null, Array(initialSettings.numDelays)).map(Number.prototype.valueOf, initialSettings.initialDelayUnits),
}

/* Initial Game State */
const initialGameState = {
  customerOrder: customerOrder, 
  currentStep: 1,
  currentWeek: 1,
  playerStates: {
    player1: initialPlayerState,
    player2: initialPlayerState,
    player3: initialPlayerState,
    player4: initialPlayerState
  }
}

/* handles playerStates - actions that require changing state of all players */ 
function playerStateReducer(state = initialGameState.playerStates, action, settings, step, week) {
  switch(action.type) {
    case PLACE_ORDER:
      return gameEngine.stateToFunctionMap[5](state, settings, action.quantity)

    case INCREMENT_STEP:
      return gameEngine.stateToFunctionMap[step](state, settings, week)

    default:
      return state
  }
}

/* Top Level Reducer - starts at gameState */
function GameStateReducer(state = initialGameState, action, settings) {
  switch (action.type) {
    case PLACE_ORDER:
    case INCREMENT_STEP:
      var new_state = {
        ...state, 
        playerStates: playerStateReducer(
          state.playerStates, action, settings, state.currentStep, state.currentWeek
        )}
        if (new_state.currentStep === 1) {
          new_state.customerOrder = new_state.playerStates[playerOrder[0]].incomingOrder
        }
      return gameEngine.incrementStep(new_state, settings)

    case RESET_GAME:
        return initialGameState

    default:
      return state
  }
}

export default GameStateReducer


