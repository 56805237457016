import React, { Component } from "react";
import ChainEndpoint from "./ChainEndpoint";
import PlayerCard from "./PlayerCard";
import { playerOrder } from '../reducers/SettingsReducer';
import { businessToRoleMap, avatarList, humanAvatar } from '../dropInText/businesses.js';
import { Col } from 'react-bootstrap';

 
class PlayerRow extends Component {
  render() {
    var avatars = [...avatarList]
    var roleMap = businessToRoleMap[this.props.business]
    var roles = []
    for (var i=playerOrder.length; i > 0; i--) {
      var target = i > 1 ? "archer-player" + (i-1) : null
      var color = '';
      if ( this.props.inventory["player" + i] < this.props.customerOrder || 
           this.props.inventory["player" + i] > 3 * this.props.customerOrder) {
        color = "red"
      } else {
        color = "green"
      }
      roles.push(
        <Col key={roleMap["player" + i]} xs={2}>
          <PlayerCard
            name={this.props.role === "player"+i ? roleMap["player"+i] + " (You)" : roleMap["player"+i]}
            inventory={this.props.inventory["player"+i]}
            customerOrder={this.props.customerOrder}
            currentStep={this.props.currentStep}
            currentWeek={this.props.currentWeek}
            avatarSrc={this.props.role === 'player' + i ? humanAvatar : avatars.shift()}
            target={target}
            archer_id={"player"+i}
            isHuman={this.props.role === "player"+i ? true : false}
            colorState={color}
          />
        </Col>
      )
    }

    return (
      <div>
        <Col className="text-center" xs={2} >
          <ChainEndpoint 
            side= "right"
            name="Materials"
          />
        </Col>
        { roles }
        <Col className="text-center" xs={2} >
          <ChainEndpoint 
            side= "left"
            name="Client"
          />
        </Col>
      </div>
    );
  }
}
 
export default PlayerRow;
