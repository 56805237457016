
/* Add value to an array */
export function addToArray(array, value) {
  return [...array, value]
}

/* Return total of array */
export function sumArray(array) {
  return array.reduce((total, qty) => total+qty, 0)
}

/* Return last item in array */
export function getLastItem(array) {
  return array[array.length-1]
}

export function calcInventoryCosts(inventories, invCost, backlogCost) {
    var costs = []
    var temp = 0
    for (var i=0; i < inventories.length; i++) {
      var cost = inventories[i] < 0 ? Math.abs(inventories[i]) * backlogCost : Math.abs(inventories[i]) * invCost
      costs.push(temp + cost)
      temp = costs[i]
    }
    return costs
  }
