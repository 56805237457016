import { connect } from 'react-redux';
import { incrementStep, placeOrder } from '../actions/GameActions';
import { gameSteps, playerOrder } from '../reducers/SettingsReducer';
import { getCurrentTimeout, getTransitionTimeClass, getTimeBetweenSteps } from '../reducers/AIPolicies';

let Components = {
  'Game': require('../components/Game.js').default,
  'GameDemo': require('../components/GameDemo.js').default
}

const mapStateToProps = state => {
  // Calculate current inventory
  var inventoryLog = state.gameState.playerStates[state.settings.role].inventoryLog  
  var inbound = state.gameState.playerStates[state.settings.role].inbound
  var outbound = state.gameState.playerStates[state.settings.role].outbound
  var incomingOrder = state.gameState.playerStates[state.settings.role].incomingOrder
  var inventory = inventoryLog.length > 0 ? inventoryLog[inventoryLog.length-1] : 0
  var lastOrder = state.gameState.playerStates[state.settings.role].lastOrder
  var playerInventories = {}
  var tempInventory;
  for (var i in playerOrder) {
    tempInventory = state.gameState.playerStates[playerOrder[i]].inventoryLog
    playerInventories[playerOrder[i]] = tempInventory.length > 0 ? tempInventory[tempInventory.length-1] : 0
  }

  return {
    role: state.settings.role,
    currentWeek: state.gameState.currentWeek,
    inventory: inventoryLog.length === 0 ? state.settings.startingInventory : inventory,
    inventoryLog: inventoryLog,
    backlogUnitCost: state.settings.backlogUnitCost,
    inventoryUnitCost: state.settings.inventoryUnitCost,
    stepDescription: gameSteps[state.gameState.currentStep],
    currentStep: state.gameState.currentStep,
    inbound: inbound,
    outbound: outbound,
    incomingOrder: incomingOrder,
    lastOrder: lastOrder,
    finalWeek: state.settings.lastWeek,
    timeoutSeconds: getCurrentTimeout(state.gameState.currentWeek, state.settings),
    transitionClass: getTransitionTimeClass(state.gameState.currentWeek, state.settings),
    timeBetweenSteps: getTimeBetweenSteps(state.gameState.currentWeek, state.settings),
    business: state.settings.business,
    customerOrder: state.gameState.customerOrder,
    playerInventories: playerInventories

  }

}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    incrementStep: () => {
      dispatch(incrementStep())
    },
    placeOrder: (qty) => {
      if (qty === '') {
        qty = 0
      }
      dispatch(placeOrder(qty))
    }
  }
}

const GameContainer = function(component) {
  return connect(
      mapStateToProps,
      mapDispatchToProps
  )(Components[component])
}

export default GameContainer
