import React, { Component } from "react";
import Plot from 'react-plotly.js';
import { businessToRoleMap } from '../dropInText/businesses';


class Graph extends Component {

  getMaxDatapointXY(data) {
    var x = 1
    var max = data[0]
    for (var i=0; i < data.length; i++) {
      if (Math.abs(data[i]) > Math.abs(max)) {
        max = data[i]
        x = i + 1
      }
    }
    return {x: x, y: max}
  }

  render() {
    var playerMap = businessToRoleMap[this.props.business]
    var weeks = []
    var count = 1
    for (var i=0; i < this.props.data[this.props.player].length; i++) {
      weeks.push(count)
      count++
    }

    /* Create traces for each player (or only Human player if desired) */
    var traces = []
    var annotations = []
    for (var player in this.props.data) {
      var trace
      var bullwhip
      if (player !== this.props.player) {
        continue
      }

      trace = {
          x: weeks,
          y: this.props.data[player],
          type: 'scatter',
          mode: 'lines',
          name: playerMap[player] + (player === this.props.role ? " (You)" : " (AI)"),
          yaxis: "y",
      }
      if (this.props.showBullwhip) {
        bullwhip = this.getMaxDatapointXY(this.props.data[player])
        annotations.push({
          x: bullwhip.x,
          y: bullwhip.y,
          text: "Bullwhip",
          showarrow: true,
          ay: -40,
          font: {
            size: 16
          }
        })
      }
      traces.push(trace)
      if (player !== this.props.role) {
        continue
      }
    }

    /* Y axis range */
    var range = [this.props.yMin, this.props.yMax]
    
    /* Create Layout */
    var layout = {
      height: this.props.height,
      title: this.props.title,
      annotations: annotations,
      xaxis: {
        title: "Week Number"
      },
      yaxis: {
        title: this.props.yTitle,
        range: range,
      },
      autosize: true,
      margin: this.props.margins
    }
    if (this.props.index < 3) {
      delete layout.yaxis.title;
    }

    var config = {
      displaylogo: false,
      modeBarButtonsToRemove: [
        'sendDataToCloud', 
        'lasso2d',
        'hoverClosestCartesian',
        'hoverCompareCartesian',
        'zoom2d',
        'select2d',
        'zoomIn2d',
        'zoomOut2d',
        'resetScal2d',
        'autoScale2d',
        'toggleSpikelines',

      ]
    }

    return (
			<div>
				<div>
          <Plot
            data={traces}
            layout={layout}
            useResizeHandler={true}
            style={{width: "100%", height: "100%"}}
            config={config}
          />
        </div>
        <div>{this.props.text}</div>
			</div>
		);
  }
}
 
export default Graph;
