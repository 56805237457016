import { connect } from 'react-redux';
import LandingPage from '../components/LandingPage';
import { resetGame } from '../actions/GameActions';


const mapStateToProps = state => {
  return {
    currentStep: state.gameState.currentStep,
    currentWeek: state.gameState.currentWeek,
    gameFinished: state.gameState.currentWeek > state.settings.lastWeek
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onStartClick: () => {
      dispatch(resetGame())
    }
  }
}

const LandingPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(LandingPage)


export default LandingPageContainer
