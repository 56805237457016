import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { businessToRoleMap } from '../dropInText/businesses';
import { Grid, Row, Col } from 'react-bootstrap';
import Avatar from '../components/Avatar';
import '../css/RoleDescription.css';
import { roleDetailsMap } from '../dropInText/businesses';

 
class RoleDescription extends Component {

  render() {
    var business_height = "300px"
    var avatar_height = "250px"

    var kpis = []
    for (var i=0; i < roleDetailsMap[this.props.business].kpis.length; i++) {
      kpis.push(
        <li key={i}>{roleDetailsMap[this.props.business].kpis[i]}</li>
      )
    }

    return (
      <div>
        <h2>Your Role Will Be: {businessToRoleMap[this.props.business][this.props.role]}</h2>
        <div className="centered role-image">
          <img alt="role" className="image-shadow" src={require("../assets/images/playing_as_"+this.props.business+".jpg")} height={business_height}/>
        </div>
        <hr />
        <Grid fluid={true}>
          <Row>
            <Col className="centered" sm={4} md={4} >
              <h3>Objectives</h3>
              <ul className="objective-list">
                <li>{ roleDetailsMap[this.props.business].objective }</li>
              </ul>
            </Col>
            <Col sm={4} md={4}>
              <div className="centered">
                <Avatar name="Isaac" imgName="isaac.jpg" height={avatar_height} />
              </div>
            </Col>
            <Col className="centered" sm={4} md={4}>
              <h3>Success Measures / KPI's</h3>
              <ul className="kpi-list">
                {kpis}
              </ul>
            </Col>
          </Row>
        </Grid>
        <h2>The game will last for 50 weeks!</h2>
        <div className='btn-container col-container'>
          <NavLink to="/play">
            <Button bsStyle="default" bsSize="large">Start Game!</Button>
          </NavLink>
        </div>
      </div>
    );
  }
}
 
export default RoleDescription;
