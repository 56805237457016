import { connect } from 'react-redux';
import { playerOrder } from '../reducers/SettingsReducer';


let Components = {
  'PlayerGraphs': require('../components/PlayerGraphs.js').default,
  'AllGraphs': require('../components/AllGraphs.js').default
}

const mapStateToProps = state => {
  // Calculate current inventory

  var inventories = {}
  var orders = {}
  for (var i = 0; i < playerOrder.length; i++) {
    inventories[playerOrder[i]] = state.gameState.playerStates[playerOrder[i]].inventoryLog
    orders[playerOrder[i]] = state.gameState.playerStates[playerOrder[i]].orderLog
  }

  return {
    inventories: inventories,
    orders: orders,
    role: state.settings.role,
    business: state.settings.business,
    inventoryUnitCost: state.settings.inventoryUnitCost,
    backlogUnitCost: state.settings.backlogUnitCost,
    numWeeks: state.settings.lastWeek
  }
}

export default function n(compName) {
  return connect(
    mapStateToProps
  )(Components[compName]);
}
