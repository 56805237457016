import React from "react";
import ReactDOM from "react-dom";
import GameContainer from "./containers/Game";
import Graphs from "./containers/Graphs";
import ClosurePage from "./components/ClosurePage";
import Header from "./components/Header.js";
import Debrief from "./components/Debrief";
import Instructions from "./components/Instructions";
import LandingPageContainer from "./containers/LandingPage";
import RoleDescriptionContainer from "./containers/RoleDescriptionContainer";
import GameMechanics from "./components/GameMechanics";
import SelectBusiness from "./containers/BusinessSelection";

import { MemoryRouter, Route } from 'react-router-dom'
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import App from './reducers/reducers';
import './css/index.css';

/* Uncomment below to show all changed in Redux Store */
//function temp() {
  //var s = store.getState()//.playerStates.materialsWarehouse
  //console.log(s)
//}

const store = createStore(App)
//store.subscribe(temp)

ReactDOM.render(
  <Provider store={store}>
    <MemoryRouter>
      <div>
        <Header />
          <div className="content">
            <Route exact path="/" component={LandingPageContainer} />
            <Route path="/mechanics" component={GameMechanics} />
            <Route path="/instructions" component={Instructions} />
            <Route path="/game_demo" component={GameContainer('GameDemo')} />
            <Route path="/select_business" component={SelectBusiness} />
            <Route path="/role" component={RoleDescriptionContainer} />
            <Route path="/play" component={GameContainer('Game')} />
            <Route path="/debrief" component={Debrief} />
            <Route path="/player_graphs" component={Graphs('PlayerGraphs')} />
            <Route path="/all_graphs" component={Graphs('AllGraphs')} />
            <Route path="/closure" component={ClosurePage} />
          </div>
      </div>
    </MemoryRouter>
  </Provider>,
  document.getElementById("root")
);
