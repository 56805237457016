
export const instructions = {
  inventoryLabel: "Materials flow from the materials supplier down to the client",
  orderLabel: "Orders flow from the client up to the materials supplier",
  steps: "Current game step of this turn",
  orderReceived: "Quantity of units requested by your downstream customer",
  incoming: "Amount of new units you are receiving this turn",
  inventory: "Your current inventory. Negative means you have a backlog",
  outbound: "Quantity of units you are shipping to your downstream customer this turn",
  lastOrder: "The number of units you ordered last turn",
  week: "Current week of game",
  time: "Amount of time left to place your order. If the timer expires, your last order will be repeated",
  placeOrder: "Enter your order value in the Order box and press Enter or click Place Order",
  redPlayer: "Player has either too little or too much inventory",
  greenPlayer: "Player has an appropriate amount of inventory"
}
