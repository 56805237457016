import React, { Component } from "react";
import "../css/ChainEndpoint.css";
 

class ChainEndpoint extends Component {

  render() {
    var side = this.props.side === "left" ? "chain-left" : "chain-right";

    return (
      <div>
        <div className={"chain-endpoint " + side}>
          <div className="chain-fill-height"><p className="chain-text">{this.props.name}</p></div>
        </div>
      </div>
    );
  }
}
 
export default ChainEndpoint;
