/*
One Round:

1. move inventory from delays container to your inventory
2. Advance Delays one box forward -- this happens by virtue of the .shift operator
3. Read and fill incoming order
    - Fill order by moving from inventory into next delay box
4. (Move orders placed card into incoming order box [maybe game board only?])
5. Determine and create your new order, place the in order placed box
6. Record your results (count your inventory/backlog for current week)
*/
import * as utilities from './utilities'
import { playerOrder, gameSteps } from './SettingsReducer';
import { orderPolicyMap, getCustomerOrder} from './AIPolicies';

/* move inventory from delays container to your inbound container */
function receiveInventory(state, settings) {
  var new_state = {...state}
  for (var i = 0; i < playerOrder.length; i++) {
    var p = playerOrder[i]
    new_state[p] = {...state[p]}
    new_state[p].delayPipeline = [...state[p].delayPipeline]
    new_state[p].inbound = new_state[p].delayPipeline.shift()   
  }
  return new_state
}

/* Receive the next orders
*/
function receiveNextOrder(state, settings, week) {
  var new_state = {...state}
  for (var i = 0; i < playerOrder.length; i++) {
    var p = playerOrder[i]
    new_state[p] = {...state[p]}

    // Receive the order from your downstream customer
    if (i === 0) {
      // Recieve an order from the customer
      new_state[p].incomingOrder = getCustomerOrder(week, settings)
    } else {
      var prevPlayer = playerOrder[i-1]
      new_state[p].incomingOrder = new_state[prevPlayer].lastOrder
    }
  }
  return new_state
}

/* Fill incoming order */
function fillIncomingOrder(state, settings) {
  var new_state = {...state}
  var outgoing = 0 
  var currentInventory = 0

  for (var i = 0; i < playerOrder.length; i++) {
    var p = playerOrder[i]
    new_state[p] = {...state[p]}

    // get current inventory for player
    var inventoryLog = new_state[p].inventoryLog
    if (inventoryLog.length === 0) {
      currentInventory = settings.startingInventory
    } else {
      currentInventory = utilities.getLastItem(inventoryLog)
    }

    // Calculate current players newInventory
    var newInventory = currentInventory + new_state[p].inbound - new_state[p].incomingOrder
    new_state[p].inventoryLog = utilities.addToArray(new_state[p].inventoryLog, newInventory)
    
    // Calculate the outgoing quantity
    if (currentInventory >= 0) {
      outgoing = Math.min(currentInventory + new_state[p].inbound, new_state[p].incomingOrder)
    } else {
      outgoing = Math.min(new_state[p].incomingOrder - currentInventory, new_state[p].inbound)
    }

    // Set players outbound property
    new_state[p].outbound = outgoing

    // push outgoing order into next players delayPipeline
    if (i > 0) {
      var nextPlayer = playerOrder[i-1]
      new_state[nextPlayer].delayPipeline = [...new_state[nextPlayer].delayPipeline, outgoing]
    }
  }

  // Push materials warehouse's last order into its own delayPipeline
  var lastPlayer = playerOrder[playerOrder.length - 1]  
  new_state[lastPlayer].delayPipeline = [...new_state[lastPlayer].delayPipeline, new_state[lastPlayer].lastOrder]

  return new_state

}

/* Place your next order */
function placeNextOrder(state, settings, quantity) {
  var new_state = {...state}
  for (var i = 0; i < playerOrder.length; i++) {
    var currentPlayer = playerOrder[i]
    new_state[currentPlayer] = {...state[currentPlayer]}
    if (settings.role === currentPlayer) {
      new_state[currentPlayer].lastOrder = parseInt(quantity, 10)
      new_state[currentPlayer].orderLog = utilities.addToArray(new_state[currentPlayer].orderLog, parseInt(quantity, 10))
    } else {
      new_state[currentPlayer].lastOrder = orderPolicyMap[currentPlayer](state[currentPlayer], settings)
      new_state[currentPlayer].orderLog = utilities.addToArray(new_state[currentPlayer].orderLog, new_state[currentPlayer].lastOrder)
    }
  }

  return new_state
}

export function incrementStep(state, settings) {
  // Rollover step if at the end
  var max_val = Math.max(...Object.keys(gameSteps).map((item) => parseInt(item, 10)))
  var nextStep = state.currentStep + 1
  var week = state.currentWeek
  if (nextStep > max_val) {
    nextStep = 1
    week++
  }

  return {...state, currentStep: nextStep, currentWeek: week}

}

function noOp(state, settings) {
  return state;
}

/* Map functions to execute depending on the state we're in */
export const stateToFunctionMap = {
  1: receiveNextOrder,
  2: receiveInventory,
  3: fillIncomingOrder,
  4: noOp,
  5: placeNextOrder
}
