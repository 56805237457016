import React, { Component } from "react";
import '../css/Avatar.css';

class Avatar extends Component {

  render() {
    return (
    <div>
        <div className="centered">
          <img className="image-shadow" src={require("../assets/images/"+this.props.imgName)} height={this.props.height} alt=""/>
          <div className="avatar-name">{this.props.name}</div>
        </div>
    </div>
    );
  }
}
 
export default Avatar;
