import React, { Component } from "react";
import {NavLink} from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { businessNameMap } from '../dropInText/businesses';
import "../css/BusinessSelection.css";
import { Grid, Row, Col } from 'react-bootstrap';


class BusinessSelection extends Component {
  render() {
    var height="200px";

    return (
      <div>
        <h2>Select Your Business Model</h2>
        <Grid fluid={true}>
          <Row className="show-grid">
            <Col className="centered" sm={6} md={6}>
                <img alt="business1" className='image-shadow' src={require('../assets/images/business1.jpg')} height={height} />
                <h2>{businessNameMap.business1}</h2>
                <NavLink className="col-container" onClick={this.props.onBusinessClick.bind(this, 'business1')} to="/role">
                  <Button bsStyle="default" bsSize="large">Select</Button>
                </NavLink>
            </Col>
            <Col className="centered" sm={6} md={6}>
                <img alt="business2" className='image-shadow' src={require('../assets/images/business2.jpg')} height={height} />
                <h2>{businessNameMap.business2}</h2>
                <NavLink className="col-container" onClick={this.props.onBusinessClick.bind(this, 'business2')} to="/role">
                  <Button bsStyle="default" bsSize="large">Select</Button>
                </NavLink>
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}
 
export default BusinessSelection;
