export const SET_ROLE = 'SET_ROLE';
export const SET_BUSINESS = 'SET_BUSINESS';

export function setRole(role) {
  return {type: SET_ROLE, role}
}

export function setBusiness(business)  {
  return {type: SET_BUSINESS, business}
}
