import React, { Component } from "react";
import '../css/ValueCard.css';
import AnimateOnChange from 'react-animate-on-change';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

 
class ValueCard extends Component {

  render() {
    var content;
    if (this.props.glyphicon) {
      content = <span className={"glyphicon " + this.props.glyphicon}></span>
    } else {
      content = this.props.name
    } 
    var main = (
          <AnimateOnChange
            baseClassName="value-box"
            animationClassName={this.props.transitionClass}
            customTag="div"
            animate={this.props.animate}>
              <div className="value-box-name" style={{backgroundColor: this.props.bgColor}}>{content}</div>
              <div className="value-box-value">{this.props.value}</div>
          </AnimateOnChange>
    )

    return (
      <div className="value-box-container">
      {this.props.tooltip ? (
      <OverlayTrigger placement="top" overlay={<Tooltip id={this.props.id}>{this.props.tooltip}</Tooltip>}>
        <div className="value-box-container">
          {main}
        </div>
        </OverlayTrigger>
      ) : main }
    </div>
    );
  }
}
 
export default ValueCard;
