import { playerOrder } from './SettingsReducer';

/* Can create custom order policy for each player if you want */
export const orderPolicyMap = {
  [playerOrder[0]]: p1OrderPolicy,
  [playerOrder[1]]: p2OrderPolicy,
  [playerOrder[2]]: p2OrderPolicy,
  [playerOrder[3]]: p3OrderPolicy,
}


/* Each user should pass their received order up the chain */
function p1OrderPolicy(playerState, settings) {
  return Math.ceil(playerState.incomingOrder * 1.20)
}

function p2OrderPolicy(playerState, settings) {
  return Math.ceil(playerState.incomingOrder * 1.30)
}

function p3OrderPolicy(playerState, settings) {
  return Math.ceil(playerState.incomingOrder * 1.40)
}


/* Policy for generating time to wait between steps 
 *
 * Note: these times must match what are defined in slow-glow/fast-glow
 * css classes found in css/ValueCard.css. Ideally these would
 * all be set by a single function but the react AnimateOnChange module only
 * allows us to change a CSS class name and we can't change the transition time
 * dynamically in the css file
 *
  * */
export function getTimeBetweenSteps(currentWeek, settings) {
  if (currentWeek <= settings.pivotWeek) {
    /* This corresponds to slow-glow, values in ms */
    return 1000
  } else {
    /* This corresponds to fast-glow, values in ms */
    return 500
  }
}


/* Policy for generating css class for transitions */
export function getTransitionTimeClass(currentWeek, settings) {
  if (currentWeek <= settings.pivotWeek) {
    return "slow-glow"
  } else {
    return "fast-glow"
  }
}


/* Policy for generating amount of time player gets to submit order (seconds) */
export function getCurrentTimeout(currentWeek, settings) {
  if (currentWeek <= settings.pivotWeek) {
    return settings.prePivotTimeout
  } else {
    return settings.postPivotTimeout
  }
}


/* Policy for generating the customer order */
export function getCustomerOrder(currentWeek, settings) {
  if (currentWeek <= settings.pivotWeek) {
    return settings.customerOrder
  } else {
    return settings.customerOrder*2
  }
}



