import {
  SET_BUSINESS
} from '../actions/SettingsActions';
import {defaultBusinessRole} from '../dropInText/businesses';

/* Static Game Settings and Parameters */
/* Description of the game steps */
export const gameSteps = {
  1: "Receive Next Order",
  2: "Receive Inventory",
  3: "Fill Incoming Order",
  4: "Place Your Order",
  5: "No-Op",  // Not shown, but here to get display working
}

/* Last step in the game steps above - do not edit*/
export const lastStep = Math.max(...Object.keys(gameSteps).map((item) => parseInt(item, 10)))

/* At this point this may or may not be enforced everywhere in the system :( - do not edit */
export const playerOrder = ['player1', 'player2', 'player3', 'player4']

/* Generate a random customer order from  3-10 */
export const customerOrder = 3+Math.floor(Math.random()*7)

/* Editable Game Settings */
export const initialSettings = {
  role: 'player3',                // Role that the human will play as. Will be changed during business selection
  business: 'business2',          // Business the human is playing as - will be changed during business selection
  costTarget: 100,                // They dropped cost, but leave in case it comes back
  numDelays: 2,                   // Number of delay delay weeks when moving inventory bt players
  initialDelayUnits: 4,           // Number of units in each delay box when game starts 
  inventoryUnitCost: 1,           // (not used - they removed cost)
  backlogUnitCost: 2,             // (not used - they removed cost)
  lastWeek: 36,                   // Last week of game
  startingInventory: 10,          // Inventory every player starts with
  customerOrder: customerOrder,   // The baseline customer order
  pivotWeek: 8,                   // Week that AI policies and time change
  prePivotTimeout: 30,            // time players get to submit order when currentWeek <= pivotWeek
  postPivotTimeout: 10            // time players get to submit order when currentWeek > pivotWeek
}


function SettingsReducer(state = initialSettings, action) {
  switch (action.type) {
    case SET_BUSINESS:
      return {...state, business: action.business, role: defaultBusinessRole[action.business]}

    default:
     return state 
  }
}


export default SettingsReducer
