import React, { Component } from "react";
import "../css/Header.css";

class Header extends Component {
  render() {
    return (
      <div className="banner">
        <img className="header-logo" src={require("../assets/images/cemex_logo.png")} height="110" alt="cemex-logo"/>
        <div className="header-logo-banner">
          <div className="header-logo-top"><span>CEMEX Supply Chain Game</span></div>
          <div className="header-logo-bottom"></div>
        </div>
      </div>

    )
  }
}

export default Header;
