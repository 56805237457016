import React, { Component } from "react";
import '../css/SubmitCard.css';
import { Button, FormControl, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';

 
class SubmitCard extends Component {
  constructor(props) {
    super(props)
    this.state = {orderValue: ''}  
    this.handleOrderChange = this.handleOrderChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }  

  /* Only allow numbers */
  handleOrderChange(event) {
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
       this.setState({orderValue: event.target.value})
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    if (!this.props.submitDisabled) {
      this.props.placeOrder(this.state.orderValue)
    }
  }

  render() {
    var main = (
            <div className={"value-box " + (this.props.activeStep ? "submit-active" : "submit-inactive")}>
              <div className="value-box-name" style={{backgroundColor: this.props.bgColor}}>{this.props.name}</div>
              <div className="value-box-value">
								<form onSubmit={this.handleSubmit}>
									<FormGroup className="order" >
										<FormControl value={this.state.orderValue} type="text" onChange={this.handleOrderChange} placeholder="Order" />
									</FormGroup>
								</form>
							</div>
            </div>
    )

    return (
      <div className="value-box-container">
      {this.props.tooltip ? (
      <OverlayTrigger placement="top" overlay={<Tooltip id={this.props.id}>{this.props.tooltip}</Tooltip>}>
        <div className="value-box-container">
          {main}
        </div>
      </OverlayTrigger>
      ) : main }
      <div className='order'>
        <Button disabled={this.props.submitDisabled} onClick={this.props.placeOrder.bind(this, this.state.orderValue)}>
            Place Order
        </Button>
      </div>
    </div>
    );
  }
}
 
export default SubmitCard;
