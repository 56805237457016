import React, { Component } from "react";
import ChainEndpoint from "./ChainEndpoint";
import PlayerCard from "./PlayerCard";
import { playerOrder } from '../reducers/SettingsReducer';
import { businessToRoleMap, avatarList, humanAvatar } from '../dropInText/businesses.js';
import { instructions } from '../dropInText/instructions.js';
import { Col } from 'react-bootstrap';

 
class InstructionalPlayerRow extends Component {
  render() {
    var avatars = [...avatarList]
    var roleMap = businessToRoleMap[this.props.business]
    var roles = []
    var colors = ['green', 'red', 'red', 'green']
    var tooltip;
    for (var i=playerOrder.length; i > 0; i--) {
      tooltip = colors[i-1] === "green" ? instructions.greenPlayer : instructions.redPlayer
      roles.push(
        <Col key={roleMap["player" + i]} xs={2}>
          <PlayerCard
            name={this.props.role === "player"+i ? roleMap["player"+i] + " (You)" : roleMap["player"+i]}
            inventory={this.props.inventory["player"+i]}
            customerOrder={this.props.customerOrder}
            currentStep={this.props.currentStep}
            currentWeek={this.props.currentWeek}
            tooltip={tooltip}
            id={"player"+i}
            avatarSrc={this.props.role === 'player' + i ? humanAvatar : avatars.shift()}
            isHuman={this.props.role === "player"+i ? true : false}
            colorState={colors[i-1]}
          />
        </Col>
      )
    }

    return (
      <div>
        <Col className="text-center" xs={2} >
          <ChainEndpoint 
            side= "right"
            name="Materials"
          />
        </Col>
        { roles }
        <Col className="text-center" xs={2} >
          <ChainEndpoint 
            side= "left"
            name="Client"
          />
        </Col>
      </div>
    );
  }
}
 
export default InstructionalPlayerRow;
