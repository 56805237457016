import GameStateReducer from './GameStateReducers';
import SettingsReducer from './SettingsReducer';


/* Send settings into GameStateReducer so they can be read */
function App(state = {settings: undefined, gameState: undefined}, action) {
  return {
    settings: SettingsReducer(state.settings, action),
    gameState: GameStateReducer(state.gameState, action, state.settings)
  }
}

export default App
