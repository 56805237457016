import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
 
class Instructions extends Component {
  render() {
    return (
      <div>
        <h2>The next screen will show a Demo of the Game Board.</h2>
        <h2>Hover over various components to learn more about them.</h2>
        <div className="btn-container col-container">
          <NavLink to="/game_demo">
            <Button bsStyle="default" bsSize="large">Next</Button>
          </NavLink>
        </div>
      </div>
    );
  }
}
 
export default Instructions;
