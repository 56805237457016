/* Internal player names - don't edit */
export const roleMap = [
  'player1',
  'player2',
  'player3',
  'player4',
]

/* Default player for each business from rolemap above
 *
 * For example if they choose business1, they will play as `player2` 
*/
export const defaultBusinessRole = {
  business1: roleMap[1],
  business2: roleMap[2],
}

/* Business Name Map - Names that will be shown for each of the two businesses */
export const businessNameMap = {
  business1: 'Cement',
  business2: 'Ready Mix'
}

/* The avatar that will be shown on the RoleDescription page */
export const humanAvatar = 'isaac-small.jpg'

/* Non-human avatars. Order isn't important as they will just be filled in */
export const avatarList = [
    'david-small.jpg',
    'anna-small.jpg',
    'jeong-small.jpg'
]

/* Details shown on the role page */
export const roleDetailsMap = {
  business1: {
    objective: "Storage of prepared goods and excess raw materials. Maintain inventory to meet needs and response to potential variations in the market.",
    kpis: [
      "Product availability (correct characteristics, when needed)",
      "Reduce inventory cost",
      "Inventory turnover",
      "Inventory to sales ratio",
      "Zero backorder rate"
    ]},
  business2: {
    objective: "Preparation of product using raw materials. Ensure production sustainability by the correct maintenance of equipment. Ensure quality of the product.",
    kpis: [
      "Product availability when needed",
      "Plant reliability and equipment uptime",
      "Highest quality that meets requirements & standards",
      "Enough product to meet needs",
      "Lean management / efficiency awards"
    ]},
}

/* Name map for each player by business */
export const businessToRoleMap = {
  business1: {
    player1: 'Distributor', 
    player2: 'Warehouse',
    player3: 'Cement Plant', 
    player4: 'Raw Materials'
  },
  business2: {
    player1: 'Distributor', 
    player2: 'Warehouse',
    player3: 'Ready Mix Plant', 
    player4: 'Raw Materials'
  }
}

