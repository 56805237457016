import { connect } from 'react-redux';
import RoleDescription from '../components/RoleDescription';


const mapStateToProps = state => {
  return {
    business: state.settings.business,
    role: state.settings.role
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

const RoleDescriptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(RoleDescription)


export default RoleDescriptionContainer
