import React, { Component } from "react";
import Graph from './Graph';
import '../css/Graphs.css';
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { Grid, Row, Col } from 'react-bootstrap';
 
class PlayerGraphs extends Component {
  render() {
    var yDim = 375
    var margins = {l:100, r: 100, b: 80, t: 25, pad: 0}
    return (
      <div>
        <h2>Your Performance</h2>
        <Grid fluid={true}>
          <Row>
            <Col sm={12} md={12}>
                <Graph 
                    showBullwhip={false}
                    height={yDim}
                    yTitle="Inventory"
                    index={0}
                    title="Inventory VS Week"
                    data={this.props.inventories}
                    role={this.props.role}
                    business={this.props.business}
                    player={this.props.role}
                    margins={margins}
                />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12}>
                <Graph 
                    showBullwhip={false}
                    height={yDim}
                    yTitle="Orders"
                    index={0}
                    title="Orders VS Week"
                    data={this.props.orders}
                    role={this.props.role}
                    business={this.props.business}
                    player={this.props.role}
                    margins={margins}
                />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <p className='kpi-list'>As you review your results and the results of the full supply chain, take a critical eye towards the following:</p>
              <ul className='kpi-list'>
                <li><b>Backlog</b> – # of stock outs that week (negative inventory) in any given week. These occur when the order placed was greater than the inventory you had available. Were there any nodes in the supply chain that had higher or lower periods of backlog? What do you think might have been the cause of these? Is there anything you could have done differently to improve your forecast?</li>
                <li><b>High Inventory</b> – # of products remaining in your “inventory” box. A high inventory means that your supply chain is not lean or minimizing its costs. Look for the peaks and low points on each of the graphs – did they occur at the same time or was there a slight delay?</li>
                  <li><b>Orders Placed</b> – When you look across the supply chain were there any periods where you appeared to be placing orders that were dramatically greater than the demand? What was driving this? Were you compensating because of low inventory? Did you not trust the order that was being placed? What would you have done differently to gain visibility into the demand?”</li>
                </ul>
            </Col>
          </Row>
        </Grid>
        <div className="btn-container col-container">
          <NavLink to="/all_graphs">
            <Button bsStyle="default" bsSize="large">Next</Button>
          </NavLink>
        </div>
      </div>
    );
  }

}
 
export default PlayerGraphs;
