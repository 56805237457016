export const INCREMENT_STEP = 'INCREMENT_STEP'
export const PLACE_ORDER = 'PLACE_ORDER'
export const RESET_GAME = 'RESET_GAME'


export function incrementStep() {
  return {type: INCREMENT_STEP}
}

export function placeOrder(quantity) {
  return {type: PLACE_ORDER, quantity}
}

export function resetGame() {
  return {type: RESET_GAME}
}

