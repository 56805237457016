import { connect } from 'react-redux';
import { setBusiness } from '../actions/SettingsActions';
import BusinessSelection from '../components/BusinessSelection';


const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onBusinessClick: business => {
      dispatch(setBusiness(business))
    }
  }
}

const SelectBusiness = connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessSelection)


export default SelectBusiness
