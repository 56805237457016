import React, { Component } from "react";


class CountdownTimer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      timeLeft: this.props.seconds
    }
    this.timer = null
    this.tick = this.tick.bind(this)
  }

  /* Clear interval timer on unmount so it doesn't keep running */
  componentWillUnmount() {
    if (this.timer !== null) {
      clearInterval(this.timer)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.runTimer !== this.props.runTimer) {
      this.setState({timeLeft: this.props.seconds})
    }
  }

  /* Callback for setInterval */
  tick() {
    /* Submit the last order */
    if (this.state.timeLeft <= 0) {
      clearInterval(this.timer)
      this.timer = null
      this.setState({timeLeft: this.props.seconds})
      this.props.timeoutCallback(this.props.defaultValue)
    } else {
      this.setState({timeLeft: this.state.timeLeft - 1})
    }
  }
  
  render() {
    if (this.props.runTimer && this.timer === null) {
      this.timer = setInterval(this.tick, 1000)
    } else if (!this.props.runTimer) {
      clearInterval(this.timer)
      this.timer = null
    }
    return(
      <div style={{color: this.state.timeLeft <= this.props.warningValue ? "red" : "black"}}>
      {this.state.timeLeft}
      </div> 
    )
  }

}



export default CountdownTimer


