import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
import { Button } from 'react-bootstrap';
 
class Debrief extends Component {
  render() {
    return (
      <div>
        <h2>The game has ended! Let's take a look at your performance.</h2>
        <div className="btn-container col-container">
          <NavLink to="/player_graphs">
            <Button bsStyle="default" bsSize="large">Next</Button>
          </NavLink>
        </div>
      </div>
    );
  }
}
 
export default Debrief;
