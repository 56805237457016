import React, { Component } from "react";
import "../css/PlayerCard.css";
import {lastStep} from '../reducers/SettingsReducer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
 
class PlayerCard extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.currentStep === lastStep) {
      return true
    }
    return false
  }

  render() {

    var bgClass = this.props.isHuman ? "human-player" : "ai-player"
    var content = (
      <div>
          <div className={"player-card fill-height glow-" + this.props.colorState + " " + bgClass}>
            <img alt="avatar" className='avatar' src={require("../assets/images/avatars/" + this.props.avatarSrc)} />
            {this.props.name}
          </div>
      </div>
    )

    return (
      <div>
        {this.props.tooltip ? (
          <OverlayTrigger placement="top" overlay={<Tooltip id={this.props.id}>{this.props.tooltip}</Tooltip>}>
          {content}
        </OverlayTrigger>
        ): content}
      </div>
    );
  }
}
 
export default PlayerCard
