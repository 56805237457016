import React, { Component } from "react";
import {NavLink} from "react-router-dom";
import { Button } from 'react-bootstrap';

 
class LandingPage extends Component {
  render() {
    return (
      <div>
        <h2>Closure</h2>
        <div className="col-container">
          <video width="640px" height="480px" controls controlsList="nodownload">
            <source src="/cxu/game/videos/closure.mp4" type="video/mp4" />
          Your browser does not support this video type
          </video>
        </div>
        <div className='btn-container col-container'>
          <NavLink onClick={this.props.onStartClick} to="/">
            <Button bsStyle="default" bsSize="large">Finish</Button>
          </NavLink>
        </div>
      </div>
    );
  }
}
 
export default LandingPage;
