import React, { Component } from "react";
import ValueCard from "./ValueCard";
import PlayerRow from "./PlayerRow";
import SubmitCard from "./SubmitCard";
import CountdownTimer from "./CountdownTimer";
import "../css/Game.css";
import { Redirect } from 'react-router-dom';
import { gameSteps, lastStep} from '../reducers/SettingsReducer';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { instructions } from '../dropInText/instructions';
import { ArcherContainer, ArcherElement } from 'react-archer';
import {Grid, Row, Col } from 'react-bootstrap';

class Game extends Component {

  render() {
    // Increment the game step using delay until last step (User interaction step)
    var submitDisabled = true
    var runTimer = false
    if (this.props.currentStep !== lastStep) {
      if (this.props.currentWeek === this.props.finalWeek+1) {
        return <Redirect to='/debrief' />
      }
      submitDisabled = true
      runTimer = false
      setTimeout(this.props.incrementStep, this.props.timeBetweenSteps) 
    } else {
      runTimer = true
      submitDisabled = false
    }

    /* ValueBox background colors */
    var inventoryBoxColor = '#3e9625'
    var orderBoxColor = '#6da0ba'
    var playerBoxColor = 'black'
    var calendarBGColor = '#f4b942'
    var timerBGColor = '#f4b942'

    // Create steps list
    var steps = []
    for (var i = 1; i <= lastStep - 1 ; i++)  {
      var item = <li key={i} className={this.props.currentStep === i+1 ? "active-step" : ""}>{gameSteps[i]}</li>
      steps.push(item)
    }


    return (
      <div>
        <Grid fluid={true}>
          <Row className="game-row">
            <Col xs={3}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip id="game-steps">{instructions.steps}</Tooltip>}>
              <div>
                <h4>Steps:</h4>
                <div className="steps">
                  <ol>
                    {steps}
                  </ol>
                </div>
              </div>
            </OverlayTrigger>
            </Col>
            <Col className="text-center" xs={2} >
              <ValueCard name="Inventory Received" animate={this.props.currentStep===3} value={this.props.inbound} 
                bgColor={inventoryBoxColor}
                tooltip={instructions.incoming}
                transitionClass={this.props.transitionClass}
                id="value-incoming" />
            </Col>
            <Col className="text-center" xs={2} >
              <ValueCard name="Current Inventory" animate={this.props.currentStep===4} value={this.props.inventory} 
                bgColor={inventoryBoxColor}
                tooltip={instructions.inventory}
                transitionClass={this.props.transitionClass}
                id="value-inventory" />
            </Col>
            <Col className="text-center" xs={2} >
              <ValueCard name="Outbound Shipment" animate={this.props.currentStep===4} value={this.props.outbound} 
                bgColor={inventoryBoxColor}
                tooltip={instructions.outbound}
                transitionClass={this.props.transitionClass}
                id="value-outbound" />
            </Col>
          </Row>
					<Row>
            <OverlayTrigger placement="top" overlay={<Tooltip id="game-steps">{instructions.inventoryLabel}</Tooltip>}>
              <div>
                <ArcherContainer strokeColor="black" strokeWidth={3}>
                  <div style={{height: "50px"}}>
                    <ArcherElement className="left-arrow-end"
                      id="top-arrow-left"
                      relations={[{
                        from: { anchor: 'right' },
                        to: {anchor: 'left', id:'top-arrow-right'},
                      label: <div className="line-label-text-top line-label-text">Inventory</div>,
                      }]}>
                      <div className='anchors'>&nbsp;</div>
                    </ArcherElement>
                    <ArcherElement className="right-arrow-end"
                      id="top-arrow-right">
                      <div className='anchors'>&nbsp;</div>
                    </ArcherElement>
                  </div>
                </ArcherContainer>
              </div>
            </OverlayTrigger>
					</Row>
          <Row className="game-row">
						<PlayerRow 
							business={this.props.business}
							role={this.props.role}
							inventory={this.props.playerInventories}
							customerOrder={this.props.customerOrder}
							currentStep={this.props.currentStep}
							currentWeek={this.props.currentWeek}
						/>
          </Row>
					<Row>
            <OverlayTrigger placement="bottom" overlay={<Tooltip id="game-steps">{instructions.orderLabel}</Tooltip>}>
              <div>
                <ArcherContainer strokeColor="black" strokeWidth={3}>
                  <div style={{height: "50px"}}>
                    <ArcherElement className="left-arrow-end"
                      id="bottom-arrow-left">
                      <div>&nbsp;</div>
                    </ArcherElement>
                    <ArcherElement className="right-arrow-end"
                      id="bottom-arrow-right"
                      relations={[{
                        from: { anchor: 'left' },
                        to: {anchor: 'right', id:'bottom-arrow-left'},
                        label: <div className="line-label-text-bottom line-label-text">Orders</div>,
                      }]}>
                      <div>&nbsp;</div>
                    </ArcherElement>
                  </div>
                </ArcherContainer>
              </div>
            </OverlayTrigger>
					</Row>
          <Row className="game-row">
            <Col className="text-center" xs={2} >
              <ValueCard glyphicon="glyphicon-calendar"
                value={this.props.currentWeek}
                bgColor={calendarBGColor}
                tooltip={instructions.week}
                animate={this.props.currentStep===1}
                transitionClass={this.props.transitionClass}
                id="value-last-order" />
            </Col>
            <Col className="text-center" xsOffset={1} xs={2} >
              <ValueCard name="Last Order Placed" 
                bgColor={orderBoxColor}
                animate={this.props.currentStep===1 && this.props.currentWeek > 1} 
                value={this.props.lastOrder} 
                tooltip={instructions.lastOrder}
                transitionClass={this.props.transitionClass}
                id="value-last-order" />
            </Col>
            <Col className="text-center" xs={2} >
              <SubmitCard name="Your Order" 
                bgColor={playerBoxColor}
                placeOrder={this.props.placeOrder}
                submitDisabled={submitDisabled}
                activeStep={this.props.currentStep === lastStep}
                id="submit-order" />
            </Col>
            <Col className="text-center" xs={2} >
              <ValueCard name="Order Received" animate={this.props.currentStep===2} 
                value={this.props.incomingOrder} 
                bgColor={orderBoxColor}
                tooltip={instructions.orderReceived}
                transitionClass={this.props.transitionClass}
                id="value-order" />
            </Col>
            <Col className="text-center" xsOffset={1} xs={2} >
              <ValueCard glyphicon="glyphicon-time"
                value={<CountdownTimer 
                runTimer={runTimer} 
                timeoutCallback={this.props.placeOrder} 
                seconds={this.props.timeoutSeconds} 
                defaultValue={this.props.lastOrder}
                transitionClass={this.props.transitionClass}
                warningValue="10"
                />}
                bgColor={timerBGColor}
                tooltip={instructions.time}
                id="value-last-order" />
            </Col>
          </Row>
        </Grid>
      </div>
      );
  }

}

export default Game;
